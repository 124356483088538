:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.tooltiptext {
  flex: 0 0 auto;
  left: 20px;
  bottom: -20px;
  display: flex;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  visibility: hidden;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  white-space: nowrap;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-white);
}

.main-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}



.glide__slides {
  width: 100%;
  height: 300px;
  display: flex;
}
.glide__slide {
  height: 100%;
  display: flex;
}
.tabs {
  display: flex;
  flex-direction: column;
}
.tab-titles {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  font-family: Optima, sans-serif;
  text-shadow: 2px 2px 2px black;
  white-space: no-wrap;
}
.tab-title {
  cursor: pointer;
  height: 32px;
  shrink: 0;
  display: flex;
  box-shadow: 5px 5px 15px 0px #000000;
  align-items: center;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.tab-title:active {
  transform: scale(0.9);
}
.tab-title-selected {
  background-color: #ccc;
}
.tab-contents {
  gap: 16px;
  border: solid 1px #ccc;
  display: flex;
  min-height: 300px;
}
.tab-content {
  padding: 0px;
  background-color: #222222;
}








.socials {
  width: 30px;
  cursor: pointer;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.btn-image {
  width: 25px;
  filter: invert();
  object-fit: cover;
  margin-right: 5px;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .tab-title {
    padding-right: unset;
  }
}
@media(max-width: 1200px) {
  .main-container {
    padding: unset;
  }
  .tabs {
    flex-direction: row;
  }
  .tab-titles {
    margin-right: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
}



