.home-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
}
.home-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 392px;
  bottom: 0px;
  filter: invert();
  height: auto;
  margin: auto;
  position: absolute;
  object-fit: cover;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 1600ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
.home-header {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  animation-name: fadeIn;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  animation-delay: 4s;
  justify-content: space-between;
  animation-duration: 1500ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-nav {
  display: none;
}
.home-image01 {
  width: 200px;
  filter: invert();
  object-fit: cover;
}
.home-container01 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-link {
  display: contents;
}
.home-link1 {
  display: contents;
}
.home-pasted-image1 {
  width: 30px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link2 {
  display: contents;
}
.home-pasted-image2 {
  width: 30px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link3 {
  display: contents;
}
.home-pasted-image3 {
  width: 30px;
  text-decoration: none;
}
.home-burger-menu {
  cursor: pointer;
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image02 {
  width: 200px;
  object-fit: cover;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon-group {
  display: none;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-container03 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-link4 {
  display: contents;
}
.home-pasted-image4 {
  width: 30px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link5 {
  display: contents;
}
.home-pasted-image5 {
  width: 30px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link6 {
  display: contents;
}
.home-pasted-image6 {
  width: 30px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7 {
  display: contents;
}
.home-pasted-image7 {
  width: 30px;
  text-decoration: none;
}
.home-container04 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-players-container {
  width: 70%;
  height: 100%;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.home-container05 {
  width: 100%;
  height: 100%;
}
.home-container06 {
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  animation-name: fadeInDown;
  animation-delay: 3s;
  animation-duration: 900ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-trovo-btn {
  background-color: #1bab78;
}
.home-text {
  color: #e7e7e7;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-twitch-btn {
  background-color: #6441a5;
}
.home-text01 {
  color: #e7e7e7;
  z-index: 100;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  animation-name: none;
  text-transform: capitalize;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-youtube-btn {
  background-color: #c4302b;
}
.home-text02 {
  color: #e7e7e7;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-vkplay-btn {
  background-color: #0077ff;
}
.home-text03 {
  color: rgb(231, 231, 231);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-container07 {
  width: 100%;
  height: 100%;
  box-shadow: 5px 2px 10px 0px #000000;
  border-width: 0px;
  animation-name: backInLeft;
  animation-delay: 2s;
  animation-duration: 1400ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-trovo-iframe {
  width: 100%;
  height: 100%;
}
.home-iframe {
  width: 100%;
  height: 100%;
}
.home-twitch-iframe {
  width: 100%;
  height: 100%;
  display: none;
}
.home-iframe1 {
  width: 100%;
  height: 100%;
}
.home-youtube-iframe {
  width: 100%;
  height: 100%;
  display: none;
}
.home-iframe2 {
  width: 100%;
  height: 100%;
}
.home-vkplay-iframe {
  width: 100%;
  height: 100%;
  display: none;
}
.home-iframe3 {
  width: 100%;
  height: 100%;
}
.home-chats-container {
  width: 30%;
  height: 100%;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-threeunits);
}
.home-container08 {
  width: 100%;
  height: 100%;
}
.home-container09 {
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  animation-name: fadeInDown;
  animation-delay: 3s;
  animation-duration: 900ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-trovo-btn1 {
  background-color: #1bab78;
}
.home-text04 {
  color: #e7e7e7;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-twitch-btn1 {
  background-color: #6441a5;
}
.home-text05 {
  color: #e7e7e7;
  z-index: 100;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  animation-name: none;
  text-transform: capitalize;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-youtube-btn1 {
  background-color: #c4302b;
}
.home-text06 {
  color: #e7e7e7;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-vkplay-btn1 {
  background-color: #0077ff;
}
.home-text07 {
  color: rgb(231, 231, 231);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-shadow: inherit;
}
.home-container10 {
  width: 100%;
  height: 100%;
  min-width: 350px;
  box-shadow: 5px 2px 10px 0px #000000;
  border-width: 0px;
  animation-name: backInDown;
  animation-delay: 2s;
  animation-duration: 1400ms;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-container11 {
  width: 100%;
  height: 100%;
}
.home-iframe4 {
  width: 100%;
  height: 100%;
}
.home-container12 {
  width: 100%;
  height: 100%;
  display: none;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  animation-name: none;
  padding-bottom: 0px;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-iframe5 {
  width: 100%;
  height: 100%;
}
.home-container13 {
  width: 100%;
  height: 100%;
  display: none;
}
.home-iframe6 {
  width: 100%;
  height: 100%;
}
.home-container14 {
  width: 100%;
  display: none;
  position: relative;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.home-text08 {
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}
.home-container16 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-players-container {
    padding-left: var(--dl-space-space-unit);
  }
  .home-text {
    display: none;
  }
  .home-text01 {
    display: none;
  }
  .home-text02 {
    display: none;
  }
  .home-text03 {
    display: none;
  }
  .home-chats-container {
    padding-right: var(--dl-space-space-unit);
  }
  .home-text04 {
    display: none;
  }
  .home-text05 {
    display: none;
  }
  .home-text06 {
    display: none;
  }
  .home-text07 {
    display: none;
  }
}
@media(max-width: 1200px) {
  .home-nav {
    display: none;
  }
  .home-container04 {
    flex-direction: column;
  }
  .home-players-container {
    width: 100%;
    height: 40%;
    padding-left: var(--dl-space-space-halfunit);
  }
  .home-container05 {
    position: relative;
  }
  .home-container07 {
    width: 100%;
  }
  .home-chats-container {
    width: 100%;
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .home-icon {
    display: flex;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-image {
    width: 300px;
    height: auto;
  }
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-container01 {
    display: none;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-container03 {
    display: none;
  }
  .home-players-container {
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-container05 {
    flex-direction: column;
  }
  .home-container06 {
    flex-direction: row;
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-container09 {
    flex-direction: row;
  }
  .home-container10 {
    min-width: auto;
  }
}
